<script lang="ts">
	import { getContext, onMount, onDestroy } from 'svelte'
	import { isOpen } from '$lib/stores/menu-store'

	
	interface Props {
		class?: string;
		isStatic?: boolean;
		children?: import('svelte').Snippet;
	}

	let { class: className = '', isStatic = false, children }: Props = $props();

	let menuRef: HTMLDivElement = $state()

	function handleClickOutside(event: MouseEvent) {
		if (!isStatic && menuRef && !menuRef.contains(event.target as Node)) {
			isOpen.set(false)
		}
	}

	onMount(() => {
		if (!isStatic) {
			document.addEventListener('mousedown', handleClickOutside)
		}
	})

	onDestroy(() => {
		if (!isStatic) {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	})
</script>

{#if $isOpen || isStatic}
	<div
		bind:this={menuRef}
		class={`absolute right-0 mt-2 origin-top-right ${className}`}
		role="menu"
		aria-orientation="vertical"
		tabindex="-1"
	>
		{@render children?.()}
	</div>
{/if}
