<script lang="ts">
	import { scale } from 'svelte/transition'
	import { isOpen } from '$lib/stores/menu-store'

	interface Props {
		class?: string;
		children?: import('svelte').Snippet;
	}

	let { class: className = '', children }: Props = $props();
	
</script>

<div class={`relative z-50 block ${className}`}>
	{#if $isOpen}
		<div
			in:scale={{ duration: 200, start: 0.95 }}
			out:scale={{ duration: 150, start: 0.95 }}
			class="transform"
		>
			{@render children?.()}
		</div>
	{/if}
</div>
