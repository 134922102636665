<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
>
	<path
		d="M36.724 14C32.2937 8.63374 24.973 6.62381 18.4237 8.97559C11.8744 11.3274 7.50453 17.5352 7.5 24.494V29"
		stroke="currentColor"
		stroke-width="4"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M12 35.816C16.6398 40.7324 23.8092 42.3214 30.0918 39.8255C36.3743 37.3297 40.4989 31.2541 40.5 24.494V21.494"
		stroke="currentColor"
		stroke-width="4"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M1.5 22.994L7.5 28.994L13.5 22.994"
		stroke="currentColor"
		stroke-width="4"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M46.5 27.494L40.5 21.494L34.5 27.494"
		stroke="currentColor"
		stroke-width="4"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
