import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/(fullwidth)": [~19,[4]],
		"/(fullwidth)/about": [20,[4]],
		"/(app)/explore": [~6,[2]],
		"/(app)/explore/[slug]": [~7,[2]],
		"/(app)/explore/[slug]/highlights": [9,[2]],
		"/(app)/explore/[slug]/[block]": [8,[2]],
		"/(fullwidth)/guide": [21,[4]],
		"/(fullwidth)/legal/licenses": [22,[4]],
		"/(fullwidth)/legal/privacy": [23,[4]],
		"/(fullwidth)/legal/terms": [24,[4]],
		"/(app)/profile/[slug]": [10,[2]],
		"/(standalone)/purchase/[slug]": [26,[5]],
		"/(app)/store": [11,[3]],
		"/(fullwidth)/travel-restrictions": [25,[4]],
		"/(app)/trips": [12,[2]],
		"/(app)/trips/[slug]": [13,[2]],
		"/(app)/trips/[slug]/highlights": [15,[2]],
		"/(app)/trips/[slug]/listing": [16,[2]],
		"/(app)/trips/[slug]/preview": [17,[2]],
		"/(app)/trips/[slug]/transfer": [18,[2]],
		"/(app)/trips/[slug]/[block]": [14,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';