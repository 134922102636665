<script lang="ts">
	import Modal from './Modal.svelte'
	import { page } from '$app/state'
	import { getContextClient, queryStore } from '@urql/svelte'
	import { browser } from '$app/environment'
	import {
		FeaturedHighlightsTripDocument,
		type FeaturedHighlightsTripQuery,
		type FeaturedHighlightsTripQueryVariables,
	} from '$lib/queries/generated/QueryTrip'
	import { constructCdnUrl } from '$lib/utils/cloudflare-loader'
	import {
		SetTripFeaturedImagesDocument,
		type SetTripFeaturedImagesMutation,
		type SetTripFeaturedImagesMutationVariables,
	} from '$lib/queries/generated/UpdateTrip'
	import { track } from '$lib/utils/track'
	import Button from '../buttons/Button.svelte'
	import modal from '$lib/stores/modal-state'
	import { onMount } from 'svelte'
	import { stopPropagation } from '$lib/utils/clickHelpers'

	let title = 'Highlight Block Menu'

	let tripId = $derived(page.params?.slug)

	const client = getContextClient()

	let tripStore = $derived(
		queryStore<FeaturedHighlightsTripQuery, FeaturedHighlightsTripQueryVariables>({
			client,
			query: FeaturedHighlightsTripDocument,
			variables: { tripId },
			pause: !tripId || !browser || !tripId?.startsWith('trp_'),
		}),
	)

	let allHighlights = $derived($tripStore?.data?.trip?.tripHighlights ?? [])

	let featured: Array<{ id: string; position: number }> = $state([])

	onMount(() => {
		featured = allHighlights
			.filter((highlight) => highlight?.featuredImagePosition != null)
			.map((highlight) => ({
				id: highlight?.id ?? '',
				position: highlight?.featuredImagePosition ?? 0,
			}))
			.sort((a, b) => a.position - b.position)
	})

	const setFeaturedImages = (vars: SetTripFeaturedImagesMutationVariables) =>
		client.mutation<SetTripFeaturedImagesMutation, SetTripFeaturedImagesMutationVariables>(
			SetTripFeaturedImagesDocument,
			vars,
		)

	function handleSetFeaturedImages() {
		track('Set Featured Images', { tripId })
		const featuredImages = featured.map((f) => ({
			id: f.id,
			position: f.position,
		}))
		setFeaturedImages({ tripId, featuredImages }).then(() => {
			modal.close()
		})
	}

	function toggleFeatured(id: string) {
		const index = featured.findIndex((f) => f.id === id)
		if (index !== -1) {
			featured = featured.filter((f) => f.id !== id)?.map((f, i) => ({ ...f, position: i + 1 }))
		} else if (featured.length < 5) {
			featured = [...featured, { id, position: featured.length + 1 }]
		}
	}

	let isValid = $derived(featured.length >= 1 && featured.length <= 5)
</script>

<Modal open={true} isLightOverride={false} size="sm:max-w-xl" {title}>
	<p class="space-y-2 w-full text-xs dark:text-brand-gray-2">Select 1-5 Images</p>
	<div class="flex flex-row flex-wrap gap-2 mt-2">
		{#each allHighlights as highlight}
			{#if highlight?.id && highlight?.highlight?.cloudflareID}
				<button
					class="rounded-lg border-2 overflow-hidden relative touch-manipulation {featured.some(
						(f) => f.id === highlight.id,
					)
						? 'border-brand-primary'
						: 'border-transparent hover:border-brand-primary/60'}"
					onclick={stopPropagation(() => toggleFeatured(highlight.id))}
				>
					<img
						class="h-20 w-20 object-cover"
						src={constructCdnUrl(highlight.highlight.cloudflareID)}
						alt={highlight.title ?? ''}
					/>
					{#if featured.some((f) => f.id === highlight.id)}
						<div
							class="absolute bottom-1 right-1 bg-brand-primary text-white rounded-full w-6 h-6 flex items-center justify-center text-xs font-bold"
						>
							{featured.find((f) => f.id === highlight.id)?.position}
						</div>
					{/if}
				</button>
			{/if}
		{/each}
	</div>
	<p class="mt-2 text-xs dark:text-brand-gray-2">
		Selected: {featured.length} / 5
	</p>
	<div class="flex flex-row justify-end">
		<Button
			class="my-4"
			theme="brand"
			size="sm"
			onclick={handleSetFeaturedImages}
			disabled={!isValid}
		>
			Set Featured Images
		</Button>
	</div>
</Modal>
