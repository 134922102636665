import type {
	DeleteTripMutation,
	DeleteTripMutationVariables,
} from '$src/lib/queries/generated/DeleteTrip'
import type {
	DuplicateTripMutation,
	DuplicateTripMutationVariables,
	InsertTripMutation,
	InsertTripMutationVariables,
} from '$src/lib/queries/generated/InsertTrip'
import {
	TripsDocument,
	type TripsQuery,
	type TripsQueryVariables,
} from '$src/lib/queries/generated/QueryTrips'
import type { Cache } from '@urql/exchange-graphcache'
import { cloneDeep } from 'lodash-es'
import type {
	InsertMediaMutation,
	InsertMediaMutationVariables,
} from '$src/lib/queries/generated/InsertMedia'
import {
	MediaConnectionDocument,
	type MediaConnectionQuery,
	type MediaConnectionQueryVariables,
} from '$src/lib/queries/generated/QueryMedia'
import type {
	MakePurchaseMutation,
	MakePurchaseMutationVariables,
} from '$lib/queries/generated/MakePurchase'
import type {
	PublishListingMutation,
	PublishListingMutationVariables,
	UnpublishListingMutation,
	UnpublishListingMutationVariables,
} from '$lib/queries/generated/PublishListing'
import type { TripsFieldsFragment } from '$lib/queries/fragments/generated/TripsFields'
import {
	TripBaseDocument,
	TripDocument,
	type TripBaseQuery,
	type TripBaseQueryVariables,
	type TripQuery,
	type TripQueryVariables,
} from '$lib/queries/generated/QueryTrip'
import type {
	UpdateTripMutation,
	UpdateTripMutationVariables,
} from '$lib/queries/generated/UpdateTrip'

export function deleteTripHandler(
	result: DeleteTripMutation,
	args: DeleteTripMutationVariables,
	cache: Cache,
) {
	const tripId = args.id

	cache.updateQuery<TripsQuery, TripsQueryVariables>({ query: TripsDocument }, (data) => {
		data = ensureTripsConnection(data)

		const updateEdges = (edges) => edges?.filter((e) => e.node.id !== tripId) ?? []

		if (data) {
			data.planning.edges = updateEdges(data.planning.edges)
			data.planning.totalCount = data?.planning?.edges?.length
			data.published.edges = updateEdges(data.published.edges)
			data.published.totalCount = data?.published?.edges?.length
			data.purchased.edges = updateEdges(data.purchased.edges)
			data.purchased.totalCount = data?.purchased?.edges?.length
		}
		return data
	})
}

export function ensureTripsConnection(data: TripsQuery | null): TripsQuery {
	const newData = data ? cloneDeep(data) : ({ __typename: 'Query' } as TripsQuery)
	if (newData.planning == null) {
		newData.planning = {
			edges: [],
			totalCount: 0,
			pageInfo: null,
			__typename: 'TripConnection',
		}
	}
	if (newData.published == null) {
		newData.published = {
			edges: [],
			totalCount: 0,
			pageInfo: null,
			__typename: 'TripConnection',
		}
	}
	if (newData.purchased == null) {
		newData.purchased = {
			edges: [],
			totalCount: 0,
			pageInfo: null,
			__typename: 'TripConnection',
		}
	}
	return newData
}

export function insertTripHandler(
	result: InsertTripMutation,
	args: InsertTripMutationVariables,
	cache: Cache,
) {
	cache.updateQuery<TripsQuery, TripsQueryVariables>({ query: TripsDocument }, (data) => {
		data = ensureTripsConnection(data)
		if (data) {
			const newEdge = {
				cursor: null,
				__typename: 'TripEdge',
				node: result.insertTrip,
			}
			// @ts-ignore
			data.planning.edges = [newEdge, ...data.planning.edges]
		}
		return data
	})
}

export function updateTripHandler(
	result: UpdateTripMutation,
	args: UpdateTripMutationVariables,
	cache: Cache,
) {
	console.log('updateTripHandler', result, args)
	cache.updateQuery<TripBaseQuery, TripBaseQueryVariables>(
		{ query: TripBaseDocument, variables: { tripId: args.id } },
		(data) => {
			if (data) {
				return {
					...data,
					title: result.updateTrip.title,
					description: result.updateTrip.description,
				}
			}
			console.log('updateTripHandler return', data)
			return data
		},
	)
}

export function duplicateTripHandler(
	result: DuplicateTripMutation,
	args: DuplicateTripMutationVariables,
	cache: Cache,
) {
	cache.updateQuery<TripsQuery, TripsQueryVariables>({ query: TripsDocument }, (data) => {
		data = ensureTripsConnection(data)
		if (data) {
			const newEdge = {
				cursor: null,
				__typename: 'TripEdge',
				node: result.duplicateTrip,
			}
			// @ts-ignore
			data.planning.edges = [newEdge, ...data.planning.edges]
		}
		return data
	})
}

export function makePurchaseHandler(
	result: MakePurchaseMutation,
	args: MakePurchaseMutationVariables,
	cache: Cache,
) {
	cache.updateQuery<TripsQuery, TripsQueryVariables>({ query: TripsDocument }, (data) => {
		data = ensureTripsConnection(data)
		if (data) {
			const newEdge = {
				cursor: null,
				__typename: 'TripEdge',
				node: result.makePurchase,
			}
			// @ts-ignore
			data.purchased.edges = [newEdge, ...data.purchased.edges]
		}
		return data
	})
}

export function publishListingHandler(
	result: PublishListingMutation,
	args: PublishListingMutationVariables,
	cache: Cache,
) {
	const tripId = result?.publishListing?.id

	cache.updateQuery<TripsQuery, TripsQueryVariables>({ query: TripsDocument }, (data) => {
		if (!data) return data

		data = ensureTripsConnection(data)
		if (!data) return data

		const planningEdges = data.planning?.edges ?? []
		const publishedEdges = data.published?.edges ?? []

		// Filter out the trip from planning
		const newPlanningEdges = planningEdges.filter((e) => e.node.id !== tripId)

		const newNode: TripsFieldsFragment = {
			__typename: 'Trip',
			id: result?.publishListing?.id,
			title: result?.publishListing?.title,
			description: result?.publishListing?.description,
			departAt: result?.publishListing?.departAt,
			returnAt: result?.publishListing?.returnAt,
			isOwner: result?.publishListing?.isOwner,
			featuredHighlights: result?.publishListing?.featuredHighlights,
			isIndestructible: result?.publishListing?.isIndestructible,
			numDestinations: result?.publishListing?.numDestinations,
			owner: result?.publishListing?.owner,
			numBlocks: result?.publishListing?.numBlocks,
			numHighlights: result?.publishListing?.numHighlights,
			numSources: result?.publishListing?.numSources,
		}

		// Create a new edge for the published list
		const newEdge: { __typename: 'TripEdge'; cursor: string | null; node: TripsFieldsFragment } = {
			cursor: null,
			__typename: 'TripEdge',
			node: newNode,
		}

		// Check if the trip is already in the published list
		const existingIndex = publishedEdges?.findIndex((e) => e.node.id === tripId)

		let newPublishedEdges: typeof publishedEdges
		if (existingIndex !== -1) {
			// Replace the existing edge
			newPublishedEdges = [
				...publishedEdges.slice(0, existingIndex),
				newEdge,
				...publishedEdges.slice(existingIndex + 1),
			]
		} else {
			// Add the new edge to the beginning of the list
			newPublishedEdges = [newEdge, ...publishedEdges]
		}

		// Update the data with immutable updates
		const newData: TripsQuery = {
			...data,
			planning: {
				...data.planning,
				edges: newPlanningEdges,
			},
			published: {
				...data.published,
				edges: newPublishedEdges,
			},
		}

		return newData
	})

	cache.updateQuery<TripQuery, TripQueryVariables>(
		{ query: TripDocument, variables: { tripId } },
		(data) => {
			if (!data) return data

			return {
				...data,
				trip: {
					...data.trip,
					unpublishedListing: {
						...data.trip?.unpublishedListing,
						isPublished: true,
					},
				},
			}
		},
	)
}

export function unpublishListingHandler(
	result: UnpublishListingMutation,
	args: UnpublishListingMutationVariables,
	cache: Cache,
) {
	const tripId = result.unpublishListing.id

	cache.updateQuery<TripsQuery, TripsQueryVariables>({ query: TripsDocument }, (data) => {
		if (!data) return data

		data = ensureTripsConnection(data)
		if (!data) return data

		const publishedEdges = data.published?.edges ?? []
		const planningEdges = data.planning?.edges ?? []

		// Filter out the trip from published
		const newPublishedEdges = publishedEdges.filter((e) => e.node.id !== tripId)

		const newNode: TripsFieldsFragment = {
			__typename: 'Trip',
			id: result.unpublishListing.id,
			title: result.unpublishListing.title,
			description: result.unpublishListing.description,
			departAt: result.unpublishListing.departAt,
			returnAt: result.unpublishListing.returnAt,
			isOwner: result.unpublishListing.isOwner,
			featuredHighlights: result.unpublishListing.featuredHighlights,
			isIndestructible: result.unpublishListing.isIndestructible,
			numDestinations: result.unpublishListing?.numDestinations,
			owner: result.unpublishListing.owner,
			numBlocks: result?.unpublishListing?.numBlocks,
			numHighlights: result?.unpublishListing?.numHighlights,
			numSources: result?.unpublishListing?.numSources,
		}

		// Create a new edge for the planning list
		const newEdge = {
			cursor: null,
			__typename: 'TripEdge' as const,
			node: newNode,
		}

		// Update the data with immutable updates
		const newData = {
			...data,
			published: {
				...data.published,
				edges: newPublishedEdges,
			},
			planning: {
				...data.planning,
				edges: [newEdge, ...planningEdges],
			},
		}

		return newData
	})

	cache.updateQuery<TripQuery, TripQueryVariables>(
		{ query: TripDocument, variables: { tripId } },
		(data) => {
			if (!data) return data

			return {
				...data,
				trip: {
					...data.trip,
					unpublishedListing: {
						...data.trip?.unpublishedListing,
						isPublished: false,
					},
				},
			}
		},
	)
}

export function insertMediaHandler(
	result: InsertMediaMutation,
	args: InsertMediaMutationVariables,
	cache: Cache,
) {
	const newMedia = result.insertMedia

	cache.updateQuery<MediaConnectionQuery, MediaConnectionQueryVariables>(
		{
			query: MediaConnectionDocument,
			variables: { tripId: args?.tripID, first: 10 },
		},
		(data) => {
			data = ensureMediaConnection(data)
			if (data.mediaConnection) {
				const newEdge = {
					cursor: null,
					__typename: 'MediaEdge',
					node: newMedia,
				}
				data.mediaConnection.edges = [
					// @ts-ignore
					newEdge,
					...data.mediaConnection.edges?.filter((e) => e.node.id !== newMedia.id).filter(Boolean),
				]
			}
			return data
		},
	)
}

export function ensureMediaConnection(data: MediaConnectionQuery | null): MediaConnectionQuery {
	const newData = data ? cloneDeep(data) : ({ __typename: 'Query' } as MediaConnectionQuery)
	if (newData.mediaConnection == null) {
		newData.mediaConnection = {
			edges: [],
			totalCount: 0,
			pageInfo: null,
			__typename: 'MediaConnection',
		}
	}
	return newData
}
