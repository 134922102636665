<script lang="ts">
	import { goto } from '$app/navigation'
	import { override } from '$lib/utils/classnames'
	import { stopPropagation } from '$lib/utils/clickHelpers'
	import VerifiedIcon from '$lib/components/icons/VerifiedIcon.svelte'
	import { computeSizeClasses } from '$lib/components/profiles/Avatar.svelte'
	import ReviewSummary from './ReviewSummary.svelte'

	interface Props {
		isPublic?: boolean
		imageUrl?: string
		name?: string
		username?: string | null
		isLightOverride?: boolean
		isVerified?: boolean
		isStatic?: boolean
		size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
	}

	let {
		isPublic = false,
		imageUrl = $bindable('/visuals/icons/default-user.svg'),
		name = 'No name available',
		username = null,
		isLightOverride = false,
		isVerified = false,
		isStatic = false,
		size = 'sm',
	}: Props = $props()
	const [width, height, sizeClasses] = computeSizeClasses(size)

	// $: computedSrc = cdnSrc(imageUrl, width)

	$effect(() => {
		imageUrl =
			name === 'thebucketlistlatina'
				? 'https://imagedelivery.net/1WwAU7RhPvfp2YzGsd1m0Q/7a239711-80c8-4d5e-a3e7-13b7fa1b9600/public'
				: imageUrl
	})

	function handleImageLoadError() {
		imageUrl = '/visuals/icons/default-user.svg'
	}

	function handleNavigateToProfile() {
		console.log('navigate to profile')
		goto(`/profile/${username}`)
	}
</script>

<button
	onclick={stopPropagation(handleNavigateToProfile)}
	disabled={!username || isStatic}
	class="ml-0 flex items-center pr-4 max-w-[50%] group touch-manipulation z-30"
>
	<div class="relative mr-2 lg:mr-3 flex-shrink-0">
		<img
			src={imageUrl}
			onerror={handleImageLoadError}
			alt=""
			class={override(
				`${sizeClasses} rounded-full bg-brand-gray-1 ring-1 ring-brand-gray-2 transition-colors ${
					username ? 'group-hover:ring-white' : ''
				}`,
			)}
		/>
		{#if isVerified}<VerifiedIcon class="absolute -bottom-1 -right-1 h-4 w-4" />{/if}
	</div>
	<div class="flex flex-col items-start w-full">
		{#if !isPublic}
			<p class="text-[12px] leading-3 tracking-wide text-brand-gray-4">Organizer</p>
		{/if}
		<p
			class={`text-xs font-medium leading-4 truncate text-ellipsis w-full text-start ${
				isLightOverride ? 'text-black' : 'text-black dark:text-brand-gray-1'
			} ${username ? 'group-hover:dark:text-white' : ''}`}
		>
			{name}
		</p>
		{#if isPublic}<ReviewSummary {isLightOverride} />{/if}
	</div>
</button>
