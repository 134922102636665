<script lang="ts">
	interface Props {
		rating?: number;
	}

	let { rating = 0 }: Props = $props();

	const totalStars = 5
	const starColor = 'text-yellow-500'
	const emptyStarColor = 'text-brand-gray-3'
	const size = 'h-3 w-3'

	// Utility function to determine the star color based on the rating
	function getStarColor(index: number): string {
		return index < rating ? starColor : emptyStarColor
	}
</script>

<div class="flex items-center">
	{#each Array(totalStars) as _, index}
		<svg
			class={`${getStarColor(index)} ${size} flex-shrink-0`}
			viewBox="0 0 20 20"
			fill="currentColor"
			aria-hidden="true"
		>
			<path
				fill-rule="evenodd"
				d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
				clip-rule="evenodd"
			/>
		</svg>
	{/each}
</div>
