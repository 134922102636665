<script lang="ts">
	import type { TripsFieldsFragment } from '$src/lib/queries/fragments/generated/TripsFields'
	import { override } from '$src/lib/utils/classnames'
	import PhotoLoop from './PhotoLoop.svelte' // Make sure to import the PhotoLoop component
	import { constructCdnUrl } from '$lib/utils/cloudflare-loader'
	import { fixTitleCharacters } from '$lib/utils/title-case'
	import SwitchIcon from '../icons/SwitchIcon.svelte'
	import TripSummary from './TripSummary.svelte'
	import Amount from '../display/Amount.svelte'
	import { toAmount } from '$lib/utils/currency'
	import { browser } from '$app/environment'
	import SkeletonBlock from '../loading/SkeletonBlock.svelte'
	import { stopPropagation } from '$lib/utils/clickHelpers'

	interface Props {
		trip: TripsFieldsFragment
		fetching?: boolean
		vertical?: boolean
		isPreview?: boolean
		basePrice?: number
		currency?: string
		onclick?: (id: string) => void
		onmouseenter?: (id: string) => void
	}

	let {
		trip,
		fetching = $bindable(false),
		vertical = false,
		isPreview = false,
		basePrice = 0,
		currency = 'USD',
		onclick,
		onmouseenter,
	}: Props = $props()

	let sanitizedDescription = $state('')

	function handleClick(event: MouseEvent | TouchEvent) {
		console.log('handleClick', trip.id)
		event.preventDefault()
		event.stopPropagation()
		if (!isPreview) fetching = true
		onclick?.(trip.id)
	}

	function handleMouseEnter(event: MouseEvent | TouchEvent) {
		event.preventDefault()
		event.stopPropagation()
		onmouseenter?.(trip.id)
	}

	function handleImageLoadError() {
		pictureUrl = '/visuals/icons/default-user.svg'
	}
	let organizer = $derived(trip?.owner ?? null)
	let pictureUrl = $state('/visuals/icons/default-user.svg')
	$effect(() => {
		pictureUrl =
			organizer?.currentProfilePictureUrl != null && organizer?.currentProfilePictureUrl != ''
				? organizer?.currentProfilePictureUrl
				: '/visuals/icons/default-user.svg'
	})
	$effect(() => {
		if (browser) {
			import('dompurify').then((DOMPurify) => {
				sanitizedDescription = DOMPurify.default
					.sanitize(
						trip?.description && trip?.description != ''
							? fixTitleCharacters(trip?.description)
							: 'no description',
						{
							ALLOWED_TAGS: [],
							ALLOWED_ATTR: [],
							KEEP_CONTENT: true,
						},
					)
					?.trim()
					.slice(0, 100)
			})
		}
	})
	let skeletonWidth = $derived(Math.min(100, Math.max(50, (trip?.title?.length ?? 0) * 2)))
	let highlights = $derived(trip?.featuredHighlights ?? [])
	let condensedClasses = $derived(
		override(
			`flex items-center w-full ${isPreview ? 'cursor-default' : 'cursor-pointer'}`,
			fetching ? 'animate-pulse' : '',
		),
	)
	let condensedClassesVertical = $derived(
		override(
			'flex items-center w-full rounded-lg hover:translate-y-[-4px] transition-transform touch-manipulation',
			fetching ? 'animate-pulse' : '',
		),
	)
	// Prepare the photo URLs for PhotoLoop
	let photoUrls = $derived(
		highlights?.map((highlight) =>
			highlight?.highlight?.cloudflareID
				? constructCdnUrl(highlight?.highlight?.cloudflareID)
				: '/visuals/icons/location-pin.svg',
		) || ['/visuals/icons/location-pin.svg'],
	)
</script>

{#if vertical}
	<button
		onclick={stopPropagation(handleClick)}
		onmouseenter={handleMouseEnter}
		class={condensedClassesVertical}
	>
		<div
			class="flex flex-col bg-brand-gray-5 items-center overflow-hidden max-w-sm w-full shadow-lg mx-auto"
		>
			<div class="relative flex-shrink-0 w-full">
				{#if photoUrls?.length > 0}
					<div class="h-52 w-full overflow-hidden flex-shrink-0">
						<PhotoLoop {vertical} photos={photoUrls} interval={800} />
					</div>
				{:else}
					<div
						class="h-32 w-full bg-brand-gray-3 overflow-hidden flex-shrink-0 flex items-center justify-center"
					>
						<img
							class="h-12 w-12 m-auto rounded-lg object-cover"
							src={'/visuals/icons/location-pin.svg'}
							alt={trip?.title}
						/>
					</div>
				{/if}
				<img
					src={pictureUrl}
					alt={organizer?.currentName}
					onerror={handleImageLoadError}
					class="h-12 w-12 flex-1 rounded-full bg-brand-gray-5 ring-4 ring-offset-0 ring-brand-gray-5 border border-brand-gray-2 absolute -bottom-3 left-4"
				/>
			</div>
			<div
				class={`p-4 pt-5 flex flex-col text-start w-full truncate text-ellipsis ${
					isPreview ? '' : 'hover:opacity-80'
				}`}
			>
				<div class="text-sm font-medium text-black dark:text-white w-full truncate text-ellipsis">
					{fixTitleCharacters(trip?.title)}
				</div>
				<p class="text-xs text-brand-gray-4 dark:text-brand-gray-3 truncate text-ellipsis">
					{@html sanitizedDescription}
				</p>
				{#if organizer}
					<div class="flex items-center space-x-2 mt-3 mb-1">
						<TripSummary
							numBlocks={trip?.numBlocks}
							numDestinations={trip?.numDestinations}
							numHighlights={trip?.numHighlights}
							numSources={trip?.numSources}
							isCompact={true}
						/>
					</div>
				{/if}
			</div>
		</div>
	</button>
{:else}
	<div class={condensedClasses}>
		<div class="flex items-center w-full max-w-full">
			<div class="relative flex-shrink-0">
				{#if photoUrls?.length > 0}
					<button
						onclick={stopPropagation(handleClick)}
						onmouseenter={handleMouseEnter}
						disabled={isPreview}
						class="h-20 w-20 rounded-lg flex-shrink-0 touch-manipulation"
					>
						<PhotoLoop {vertical} photos={photoUrls} interval={800} />
					</button>
				{:else}
					<button
						onclick={stopPropagation(handleClick)}
						onmouseenter={handleMouseEnter}
						disabled={isPreview}
						class="touch-manipulation"
					>
						<img
							class="h-20 w-20 p-6 rounded-lg object-cover bg-brand-gray-3"
							src={'/visuals/icons/location-pin.svg'}
							alt={trip?.title}
						/>
					</button>
				{/if}
				{#if isPreview}
					<!-- svelte-ignore node_invalid_placement_ssr -->
					<button
						onclick={stopPropagation(handleClick)}
						class="absolute z-30 -bottom-2 -right-2 bg-white dark:bg-brand-primary/80 hover:dark:bg-brand-primary rounded-full p-1 shadow-md touch-manipulation"
						disabled={fetching}
					>
						<SwitchIcon class="w-4 h-4 text-white" />
					</button>
				{:else if organizer}
					<img
						src={pictureUrl}
						alt={organizer?.currentName}
						class="h-7 w-7 absolute -bottom-2 -right-2 rounded-full bg-brand-gray-5 ring-4 ring-offset-0 ring-brand-gray-5 border border-brand-gray-2"
					/>
				{/if}
			</div>
			<button
				onclick={stopPropagation(handleClick)}
				onmouseenter={handleMouseEnter}
				disabled={isPreview}
				class={`pl-4 h-20 flex flex-col text-start justify-between truncate text-ellipsis w-full touch-manipulation ${
					isPreview ? '' : 'hover:opacity-80'
				}`}
			>
				<div class="flex flex-col w-full">
					<div
						class="text-sm sm:text-base font-normal text-black dark:text-white w-full truncate text-ellipsis"
					>
						{fixTitleCharacters(trip?.title)}
					</div>

					<div
						class="text-xs text-brand-gray-4 dark:text-brand-gray-3 w-full truncate text-ellipsis"
					>
						{#if !browser}
							<SkeletonBlock width={`${skeletonWidth}%`} height={'12px'} class="mt-1" />
						{:else}
							{@html sanitizedDescription}
						{/if}
					</div>
				</div>
				<div class="flex justify-between w-full">
					<TripSummary
						numBlocks={trip?.numBlocks}
						numDestinations={trip?.numDestinations}
						numHighlights={trip?.numHighlights}
						numSources={trip?.numSources}
						isCompact={true}
					/>
					{#if !isPreview && basePrice > 0}
						<Amount class="text-sm text-brand-gray-2" amount={toAmount(basePrice ?? 2900)} />
					{/if}
				</div>
			</button>
		</div>
	</div>
{/if}
