<!-- DescriptionModal.svelte -->
<script lang="ts">
	import Modal from './Modal.svelte'
	import modal, { type ExtraTypeMap } from '$lib/stores/modal-state'
	import { urlState } from '$lib/stores/url-state'
	import { track } from '$lib/utils/track'
	import DescriptionForm from './DescriptionForm.svelte'
	import Button from '$lib/components/buttons/Button.svelte'
	import type { MutateTripContext } from '$lib/context/async-operations'
	import { getContext } from 'svelte'
	import { getContextClient, queryStore } from '@urql/svelte'
	import {
		TripBaseDocument,
		type TripBaseQuery,
		type TripBaseQueryVariables,
	} from '$lib/queries/generated/QueryTrip'
	import { browser } from '$app/environment'

	let { blockId, tripId, itineraryId } = $derived($urlState)

	const mutateTrip = getContext<MutateTripContext>('mutateTrip')

	const client = getContextClient()

	let tripStore = $derived(
		queryStore<TripBaseQuery, TripBaseQueryVariables>({
			client,
			query: TripBaseDocument,
			variables: { tripId },
			requestPolicy: 'cache-first',
			pause: !tripId || !browser || !tripId?.startsWith('trp_'),
		}),
	)

	let defaultRootBlockId = $derived(
		$tripStore?.data?.trip?.rootBlocks?.find((b) => b.position === 0)?.id ?? null,
	)

	let isSaving = $state(false)
	let error = $state('')

	const blockData = modal.extra<ExtraTypeMap, 'edit-description'>('edit-description')
	let description = $derived(blockData.description ?? '')

	let currentContent = $state('')
	$effect(() => {
		currentContent = description ?? ''
	})
	let hasDescriptionError = $state(false)

	function handleChange(content: string, error: boolean) {
		currentContent = content
		hasDescriptionError = error
	}

	async function handleSave() {
		if (isSaving) return
		isSaving = true

		try {
			if (blockId === null && (itineraryId === null || itineraryId === defaultRootBlockId)) {
				track('Update Trip Description', { tripId })
				mutateTrip.updateTripDescription(tripId, currentContent)
			} else if (blockId !== null) {
				track('Update Block Description', { blockId })
				mutateTrip.updateBlockDescription(blockId, currentContent)
			} else if (itineraryId !== null) {
				track('Update Itinerary Description', { itineraryId })
				mutateTrip.updateBlockDescription(itineraryId, currentContent)
			}
			isSaving = false
			modal.close()
		} catch (err) {
			error = 'Failed to save changes. Please try again.'
		}
	}
</script>

<Modal open={true} isLightOverride={false} size="sm:max-w-xl" title="Edit Description">
	{#if currentContent}
		<DescriptionForm initialContent={currentContent} onchange={handleChange} />
	{/if}

	{#snippet actions()}
		<Button
			disabled={isSaving || hasDescriptionError}
			loading={isSaving}
			onclick={handleSave}
			theme="brand"
			size="sm">Save</Button
		>
	{/snippet}
</Modal>
