import type { BlockType, CustomColor, CustomIcon } from '$lib/graphql/types'
import type { MapState } from '$lib/utils/map'
import { writable, type Readable } from 'svelte/store'
import type { MapViewQuery } from '../queries/generated/QueryMapView'

export const stickySection = writable<string | null>(null)

export type MapBoundsType = { south: number; west: number; north: number; east: number }
export type MapMarkerType = { lat: number; lng: number; placeId: string }

export interface PinState {
	id: string
	lat: number
	lng: number
	isSearch?: boolean
	icon?: CustomIcon
	color?: CustomColor
	blockType?: BlockType
	isActive?: boolean
	isHighlighted?: boolean
	isVisible?: boolean
	shouldHighlight?: boolean
}

export interface SearchPinState extends PinState {
	placeId: string
}

export interface BlockPinState extends PinState {
	blockId: string
	parentId?: string
}

export type BlockState = {
	listingIds: string[]
	currentDestination: MapMarkerType | null
	searchResults: PinState[]
	searchSelected: string | null
	searchArea: Boolean
	hoveringPlaceId: string | null
	googleSdk: any | null
	googleMap: google.maps.Map | null
	mapBounds: MapBoundsType | null
	mapCenter: { lat: number; lng: number } | null
	mapMarkers: { lat: number; lng: number }[]
	recenterMap: boolean
	refetchMapBlocks: false
	dragStart: boolean
	mapState: MapState
	isMinimizedView: boolean
}

function createInitialState(): BlockState {
	return {
		listingIds: [],
		currentDestination: null,
		searchResults: [],
		searchSelected: null,
		searchArea: false,
		hoveringPlaceId: null,
		googleSdk: null,
		googleMap: null,
		mapBounds: null,
		mapCenter: null,
		mapMarkers: [],
		recenterMap: false,
		refetchMapBlocks: false,
		dragStart: false,
		mapState: null,
		isMinimizedView: false,
	}
}

const store = writable<BlockState>(createInitialState())

const set = (newState: Partial<BlockState>) => store.update((state) => ({ ...state, ...newState }))

const setListingIds = (listingIds: string[]): void => {
	set({ listingIds: listingIds })
}

const setCurrentDestination = (dest: MapMarkerType): void => {
	set({ currentDestination: dest })
}

const setSearchResults = (places: PinState[]): void => {
	set({ searchResults: places, mapState: 'search' })
}

const setSearchSelected = (selected: string | null): void => {
	set({ searchSelected: selected })
}

const setSearchArea = (search: Boolean): void => {
	set({ searchArea: search })
}

const setHoveringPlaceId = (locationId: string): void => {
	set({ hoveringPlaceId: locationId })
}

const cleartHoveringPlaceId = (): void => {
	set({ hoveringPlaceId: null })
}

const setGoogleSdk = (googleSdk: any): void => {
	set({ googleSdk: googleSdk })
}

const setGoogleMap = (googleMap: google.maps.Map): void => {
	set({ googleMap: googleMap })
}

const setMapBounds = (mapBounds: MapBoundsType): void => {
	set({ mapBounds: mapBounds })
}

const setMapCenter = (mapCenter: { lat: number; lng: number }): void => {
	set({ mapCenter: mapCenter })
}

const setMapMarkers = (mapMarkers: { lat: number; lng: number }[]): void => {
	set({ mapMarkers: mapMarkers })
}

const setRecenterMap = (recenterMap: boolean): void => {
	set({ recenterMap: recenterMap })
}

const setMapState = (mapState: MapState): void => {
	set({ mapState: mapState, recenterMap: true })
}

const setIsMinimizedView = (isMinimizedView: boolean): void => {
	set({ isMinimizedView: isMinimizedView })
}

const clearAllCustom = (): void => {
	// $inspect('clearAllCustom')
	set({
		listingIds: [],
		currentDestination: null,
		searchResults: [],
		hoveringPlaceId: null,
		mapMarkers: [],
		searchArea: false,
		searchSelected: null,
	})
}

const clearCurrentDestination = (): void => {
	set({ currentDestination: null })
}

const blockState: Readable<BlockState> & {
	setListingIds: typeof setListingIds
	setCurrentDestination: typeof setCurrentDestination
	setSearchResults: typeof setSearchResults
	setSearchSelected: typeof setSearchSelected
	setSearchArea: typeof setSearchArea
	setHoveringPlaceId: typeof setHoveringPlaceId
	cleartHoveringPlaceId: typeof cleartHoveringPlaceId
	clearAllCustom: typeof clearAllCustom
	setGoogleSdk: typeof setGoogleSdk
	setGoogleMap: typeof setGoogleMap
	clearCurrentDestination: typeof clearCurrentDestination
	setMapBounds: typeof setMapBounds
	setMapCenter: typeof setMapCenter
	setMapMarkers: typeof setMapMarkers
	setRecenterMap: typeof setRecenterMap
	setMapState: typeof setMapState
	setIsMinimizedView: typeof setIsMinimizedView
} = {
	subscribe: store.subscribe,
	setListingIds,
	setCurrentDestination,
	setSearchResults,
	setSearchSelected,
	setSearchArea,
	setHoveringPlaceId,
	cleartHoveringPlaceId,
	clearAllCustom,
	setGoogleSdk,
	setGoogleMap,
	clearCurrentDestination,
	setMapBounds,
	setMapCenter,
	setMapMarkers,
	setRecenterMap,
	setMapState,
	setIsMinimizedView,
}

export default blockState
