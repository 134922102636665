<script lang="ts">
	interface Props {
		link?: string
		src?: string
		alt?: string
	}

	let { link = null, src = null, alt = null }: Props = $props()
</script>

<div class="flex items-center justify-center col-span-1 p-4 bg-white sm:p-6 lg:p-4">
	<a href={link} rel="noreferrer" target="_blank">
		<img {src} {alt} loading="lazy" width={120} height={48} />
	</a>
</div>
