export declare type ImageLoaderProps = {
	src: string | null | undefined
	width: number
	quality?: number
}
export declare type ImageLoader = (resolverProps: ImageLoaderProps) => string | undefined

const normalizeSrc = (src: string | null | undefined): string => {
	if (!src) return ''
	return src[0] === '/' ? src.slice(1) : src
}

export const cloudflareLoader: ImageLoader = ({ src, width, quality }) => {
	// Handle null/undefined/empty src
	if (!src) return ''
	if (typeof src !== 'string') return ''
	if (src.trim() === '') return ''

	// SVG bypass
	if (src.toLowerCase().endsWith('.svg')) {
		return undefined
	}

	const params = [`width=${width}`, 'gravity=auto', 'fit=cover', 'metadata=none', 'format=auto']
	if (quality) {
		params.push(`quality=${quality}`)
	}
	const paramsString = params.join(',')

	let normalizedSrc = src
	if (typeof window !== 'undefined' && src.startsWith('/')) {
		normalizedSrc = `${window.location.origin}${src}`
	}

	return `https://airheart.com/cdn-cgi/image/${paramsString}/${normalizeSrc(normalizedSrc)}`
}

export function cdnSrc(
	src: string | null | undefined,
	width: number,
	quality?: number,
): string | undefined {
	if (src === undefined) return undefined
	if (!src || src.trim() === '') return src ?? ''

	const loader = cloudflareLoader({ src, width, quality })
	if (loader !== undefined) return loader
	return src
}

export function constructCdnUrl(imageId: string | null | undefined): string {
	const safeImageId = imageId ?? ''
	const encodedImageId = encodeURIComponent(safeImageId)
	return `https://airheart.com/cdn-cgi/imagedelivery/1WwAU7RhPvfp2YzGsd1m0Q/${encodedImageId}/public`
}

export function constructVideoPlaybackUrl(videoId: string | null | undefined): string {
	const safeVideoId = videoId ?? ''
	const encodedVideoId = encodeURIComponent(safeVideoId)
	return `https://customer-28ft6mgmjaoimvfg.cloudflarestream.com/${encodedVideoId}/iframe`
}

export function constructVideoThumbnailUrl(videoId: string | null | undefined): string {
	const safeVideoId = videoId ?? ''
	const encodedVideoId = encodeURIComponent(safeVideoId)
	return `https://customer-28ft6mgmjaoimvfg.cloudflarestream.com/${encodedVideoId}/thumbnails/thumbnail.jpg?time=1s&duration=4s`
}
