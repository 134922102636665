<script lang="ts">
	import { customColors, getCustomColorBgClassName } from '$lib/utils/custom-colors'
	import { customIconCategories, customIcons, getCustomIconSvgUrl } from '$lib/utils/custom-icons'
	import { track } from '$lib/utils/track'
	import Modal from './Modal.svelte'
	import { page } from '$app/state'
	import { getContextClient, queryStore } from '@urql/svelte'
	import {
		UpdateBlockDetailsDocument,
		type UpdateBlockDetailsMutation,
		type UpdateBlockDetailsMutationVariables,
	} from '$lib/queries/generated/UpdateBlock'
	import type { CustomColor, CustomIcon } from '$lib/graphql/types'
	import { CustomColor as CustomColorEnum, CustomIcon as CustomIconEnum } from '$lib/graphql/enums'
	import {
		BlockDocument,
		type BlockQuery,
		type BlockQueryVariables,
	} from '$lib/queries/generated/QueryBlock'
	import { browser } from '$app/environment'
	import SleekButton from '../buttons/SleekButton.svelte'
	import CustomIconCategorySelection from '../forms/CustomIconCategorySelection.svelte'
	import { stopPropagation } from '$lib/utils/clickHelpers'

	let title = 'Customize Your Icon'

	let blockId = $derived(page.params?.block)
	let tripId = $derived(page.params?.slug)

	const client = getContextClient()

	let blockStore = $derived(
		queryStore<BlockQuery, BlockQueryVariables>({
			client,
			query: BlockDocument,
			variables: { id: blockId },
			pause:
				!tripId ||
				!browser ||
				!tripId?.startsWith('trp_') ||
				!blockId ||
				!blockId?.startsWith('blk_'),
		}),
	)

	let block = $derived($blockStore?.data?.block)
	let iconClass = $derived(getCustomIconSvgUrl(block?.icon, block?.blockType))
	let colorClass = $derived(getCustomColorBgClassName(block?.color, block?.blockType))

	const updateBlock = (vars: UpdateBlockDetailsMutationVariables) =>
		client.mutation<UpdateBlockDetailsMutation, UpdateBlockDetailsMutationVariables>(
			UpdateBlockDetailsDocument,
			vars,
		)

	function handleIconSelect(icon: CustomIcon) {
		track('Update Block Icon', { tripId, blockId })
		updateBlock({
			id: blockId,
			input: { icon },
		}).then((res) => {
			// console.log('res', res.data.updateBlockDetails.description)
		})
	}

	function handleColorSelect(color: CustomColor) {
		track('Update Block Icon', { tripId, blockId })
		updateBlock({
			id: blockId,
			input: { color },
		}).then((res) => {
			// console.log('res', res.data.updateBlockDetails.description)
		})
	}

	function handleResetToDefault() {
		track('Update Block Icon', { tripId, blockId })
		updateBlock({
			id: blockId,
			input: { color: CustomColorEnum.Default, icon: CustomIconEnum.Default },
		}).then((res) => {
			// console.log('res', res.data.updateBlockDetails.description)
		})
	}
</script>

<Modal open={true} isLightOverride={false} size="sm:max-w-xl" {title}>
	<div class="flex items-center justify-between">
		<div
			class={`m-1 flex flex-shrink-0 items-center justify-center h-10 w-10 rounded-md ${colorClass}`}
		>
			<img class="h-5 w-5" src={iconClass} alt={block?.title} />
		</div>

		<SleekButton onclick={handleResetToDefault}>Revert to Default</SleekButton>
	</div>

	<p class="px-2 pt-4 pb-2 text-xs tracking-wide text-brand-gray-3">Color</p>
	<div class="px-0 grid grid-cols-8 gap-0">
		{#each customColors as color}
			<button
				aria-label={color?.name}
				onclick={stopPropagation(() => handleColorSelect(color?.name))}
				class="hover:bg-brand-gray-6 p-2 rounded-md mx-auto touch-manipulation"
			>
				<div class={`h-6 w-6 rounded-md ${color?.bgClassName}`}></div>
			</button>
		{/each}
	</div>

	<div class="pb-16">
		{#each customIconCategories as category}
			{@const categoryIcons = customIcons.filter((icon) => icon.category === category.name)}
			<CustomIconCategorySelection
				{categoryIcons}
				categoryTitle={category.label}
				onclick={handleIconSelect}
			/>
		{/each}
	</div>
</Modal>
