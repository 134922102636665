<script lang="ts">
	import Modal from './Modal.svelte'
	import { getContextClient, queryStore } from '@urql/svelte'
	import { BlockType } from '$lib/graphql/enums'
	import {
		BlockDocument,
		type BlockQuery,
		type BlockQueryVariables,
	} from '$lib/queries/generated/QueryBlock'
	import { browser } from '$app/environment'
	import ListItemTemplate from './AddToTrip/ListItemTemplate.svelte'
	import {
		TripBaseDocument,
		type TripBaseQuery,
		type TripBaseQueryVariables,
	} from '$lib/queries/generated/QueryTrip'
	import modal from '$lib/stores/modal-state'
	import { urlState } from '$lib/stores/url-state'

	let title = 'Highlight Block Menu'

	let { tripId, activeBlockId } = $derived($urlState)

	const client = getContextClient()

	let tripStore = $derived(
		queryStore<TripBaseQuery, TripBaseQueryVariables>({
			client,
			query: TripBaseDocument,
			variables: { tripId },
			requestPolicy: 'cache-first',
			pause: !tripId || !browser || !tripId?.startsWith('trp_'),
		}),
	)

	let blockStore = $derived(
		queryStore<BlockQuery, BlockQueryVariables>({
			client,
			query: BlockDocument,
			variables: { id: activeBlockId },
			pause:
				!tripId ||
				!browser ||
				!tripId?.startsWith('trp_') ||
				!activeBlockId ||
				!activeBlockId?.startsWith('blk_'),
		}),
	)

	let hasCopies = $derived($blockStore?.data?.block?.copies?.length > 0)

	let isIndestructible = $derived($tripStore?.data?.trip?.isPurchased)

	function handleDeleteBlock() {
		if (hasCopies) return

		modal.open('confirm-delete-block', {
			blockId: activeBlockId,
			blockType: BlockType.Highlight,
			blockTitle: $blockStore?.data?.block?.title,
		})
	}
</script>

<Modal open={true} isLightOverride={false} size="sm:max-w-xl" {title}>
	<p class="text-xs text-brand-gray-4 my-3">Add To Block</p>

	<p class="space-y-2 w-full text-xs dark:text-brand-gray-2">Nothing to add</p>
	<!-- {/if} -->
	{#if !isIndestructible}
		<p class="text-xs text-brand-gray-4 mb-3 mt-4">Manage Block</p>
		<div class="flex flex-col items-start space-y-2 w-full">
			<ListItemTemplate
				onclick={handleDeleteBlock}
				size="small"
				title={'Delete Block'}
				iconUrl={'/visuals/icons/delete-white.svg'}
				bgColor={'bg-brand-gray-5'}
				description={'Permanently delete this block and all of its contents'}
				isTruncated={false}
				isClickable={!hasCopies}
			/>
		</div>
		{#if hasCopies}
			<div class="flex items-center justify-center mt-3">
				<!-- <span class="h-1 w-1 bg-brand-primary animate-pulse rounded-full mx-4" /> -->
				<p class="text-xs text-brand-primary/80">
					This block is copied into another itinerary and cannot be deleted
				</p>
			</div>
		{/if}
	{/if}
</Modal>
